import {Observable, ReplaySubject, Subject, combineLatest, from, of} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {FilterView} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/filter_view_pb';
import {Layer, Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {
  ASSETS_LAYER_ID,
  DEFECTS_LAYER_ID,
  SOLAR_INSIGHTS_LAYER_ID,
  STREETVIEW_RECENCY_LAYER_ID,
  SUNROOF_LAYER_ID,
} from '../constants/layer';
import {QUERY_PARAMS, ROUTE} from '../constants/paths';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
// import {FilterViewsService} from '../services/filter_views_service';
import {ConfigService} from '../services/config_service';
import {FeaturesCountService} from '../services/features_count_service';
import {FilterViewsService} from '../services/filter_views_service';
import {LayersFilterService} from '../services/layers_filter_service';
import {LayersService} from '../services/layers_service';
import {MapService} from '../services/map_service';
import {NetworkService} from '../services/network_service';
import {SolarInsightsService} from '../services/solar_insights_service';
import {StreetViewRecencyService} from '../services/streetview_recency_service';
import {UploadService} from '../services/upload_service';
import {FilterEditingStateEvent, FilterMap, FilterUpdate, LayerFilters} from '../typings/filter';
import {VisibilityByLayerUpdate} from '../typings/map';
import {
  extractFiltersByLayer,
  setFiltersForLayer,
  toggleLayer,
  toggleLayers,
} from '../utils/filter_views';
import {waitFor} from '../utils/rxjs';

const TOAST_DURATION_MS = 5000;

interface ZoomDisplay {
  visible: boolean;
  enabled: boolean;
}

/**
 * Component to render the map layers component.
 */
@Component({
  selector: 'map-layers',
  templateUrl: './map_layers_component.ng.html',
  styleUrls: ['./map_layers_component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapLayersComponent implements OnInit, OnDestroy {
  layers: Layer[] = [];
  visibilityByLayerId = new Map<string, boolean>();
  editingByLayer = new Map<string, boolean>();
  sunroofLayerId = SUNROOF_LAYER_ID;
  reset = new Subject<void>();

  // Start with an empty state.
  currentView = new FilterView();
  // Don't select any view by default.
  selectedViewId = '';
  isMapTableToggleEnabled = false;

  readonly layersWithFeatures = new Map<string, boolean>([]);
  readonly LayerType = Layer_LayerType;
  protected solarInsightsLayerId = SOLAR_INSIGHTS_LAYER_ID;
  protected assetLayerId = ASSETS_LAYER_ID;
  private readonly layersReady = new ReplaySubject<void>(1);
  private readonly destroyed = new Subject<void>();

  constructor(
    readonly recencyService: StreetViewRecencyService,
    private readonly analyticsService: AnalyticsService,
    readonly configService: ConfigService,
    private readonly filterViewsService: FilterViewsService,
    private readonly layersService: LayersService,
    private readonly mapService: MapService,
    readonly networkService: NetworkService,
    private readonly layersFilterService: LayersFilterService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly featuresCountService: FeaturesCountService,
    private readonly router: Router,
    private readonly solarInsightsService: SolarInsightsService,
    private readonly uploadService: UploadService,
  ) {
    this.layersService.getAllLayersMetadata().pipe(take(1), takeUntil(this.destroyed)).subscribe();
  }

  ngOnInit() {
    this.loadInitialFilterStateAndLayers();
    this.mapService.getLocationPin();
    this.listenForLayerVisibilityUpdates();
    this.listenForFilterUpdates();
    this.listenForViewIdUpdates();
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  onEditingChanged(event: FilterEditingStateEvent) {
    if (event.isReset) {
      this.reset.next();
    } else {
      this.editingByLayer.set(event.layerId, event.editing);
    }
  }

  showUploadButton(layer: Layer): boolean {
    return (
      layer.id === DEFECTS_LAYER_ID
      //&& this.configService.uploadViewEnabled
    );
  }

  shouldEnableTags(layer: Layer): boolean {
    return layer.id !== SOLAR_INSIGHTS_LAYER_ID && layer.id !== STREETVIEW_RECENCY_LAYER_ID;
  }

  shouldShowRecencyLegend(layer: Layer): boolean {
    return layer.id === STREETVIEW_RECENCY_LAYER_ID;
  }

  shouldEnableAnnotations(layer: Layer): boolean {
    return (
      this.configService.annotationFilteringEnabled &&
      (layer.id === DEFECTS_LAYER_ID ||
        (this.configService.annotationFilteringAssetsEnabled && layer.id === ASSETS_LAYER_ID))
    );
  }

  layerFiltersEnabled(layer: Layer) {
    return layer.layerType === Layer_LayerType.NATIVE || layer.layerType === Layer_LayerType.ASSETS;
  }

  openPhotoUpload() {
    if (this.configService.uploadFormImprovementsEnabled) {
      this.uploadService.renderUploadDialog();
    } else {
      this.router.navigateByUrl(ROUTE.PHOTO_UPLOAD);
    }
  }

  toggleLayer(layer: Layer) {
    if (this.configService.solar2Enabled && layer.id === SOLAR_INSIGHTS_LAYER_ID) {
      this.solarInsightsService.filterByFeederNames();
    }

    const visible = this.visibilityByLayerId.get(layer.id)!;
    const userInitiated = true;
    this.setLayerVisibility(layer, !visible, userInitiated);
    this.updateCurrentViewLayerVisibility(layer, !visible);

    // Since we retain the filter state between layer toggles,
    // fetch layer filters and update the current filter view.
    this.fetchLayerFiltersUpdates(layer.id)
      .pipe(takeUntil(this.destroyed))
      .subscribe((updates: LayerFilters) => {
        this.updateCurrentViewLayerFilters(updates);
      });
  }

  clearAllFilters() {
    const userInitated = true;
    for (const layer of this.layers) {
      this.layersFilterService.updateFilterMap(layer.id, {}, userInitated);
      this.layersFilterService.updateIncludeInactive(layer.id, false, userInitated);
      if (this.visibilityByLayerId.get(layer.id)!) {
        this.toggleLayer(layer);
      }
    }
  }

  /**
   * Update the value of aria-label in the DOM depending on map layer
   * visibility.
   */
  setAriaLabel(layerName: string, visible: boolean) {
    const altVisibility = visible ? 'invisible' : 'visible';
    const ariaLabel = `Make layer ${layerName} ${altVisibility}`;
    return ariaLabel;
  }

  isVisible(layer: Layer): boolean {
    return this.visibilityByLayerId.get(layer.id)!;
  }

  getLayerId(layer: Layer): string {
    return layer.id || '';
  }

  private loadInitialFilterStateAndLayers() {
    combineLatest([
      this.layersService.onLayersMetadataChanged(),
      this.mapService.onLayerVisibilityChanged(),
      // TODO(reubenn): refactor layerFiltersInitialState to be a method so that
      // the underlying subject isn't accessed directly.
      this.layersFilterService.layerFiltersInitialState,
    ])
      .pipe(first(), takeUntil(this.destroyed))
      .subscribe({
        next: ([layers, visibilityByLayerUpdate, layerFilters]: [
          Layer[],
          VisibilityByLayerUpdate,
          LayerFilters[],
        ]) => {
          this.layers = layers;
          this.loadCachedFilterState(visibilityByLayerUpdate.visibilityByLayerId, layerFilters);
          this.layersReady.next();
          this.changeDetectionRef.detectChanges();
        },
        error: () => {
          this.snackBar.open('Could not load filter state.', '', {
            duration: TOAST_DURATION_MS,
          });
        },
      });
  }

  private listenForLayerVisibilityUpdates() {
    this.mapService
      .onLayerVisibilityChanged()
      .pipe(waitFor(this.layersReady), takeUntil(this.destroyed))
      .subscribe((visibilityByLayerUpdate: VisibilityByLayerUpdate) => {
        this.visibilityByLayerId = visibilityByLayerUpdate.visibilityByLayerId;
        const visibleLayers = this.findVisibleLayers(this.visibilityByLayerId);
        this.listenForLayerFeaturesCount(visibleLayers, true);
        this.changeDetectionRef.detectChanges();
      });
  }

  private listenForFilterUpdates() {
    this.layersFilterService
      .layerFiltersUpdated()
      .pipe(
        waitFor(this.layersReady),
        switchMap((filterUpdate: FilterUpdate) =>
          combineLatest([
            this.fetchLayerFiltersUpdates(filterUpdate.layerId),
            of(!!filterUpdate.userInitiated),
          ]),
        ),
        takeUntil(this.destroyed),
      )
      .subscribe(([updates]: [LayerFilters, boolean]) => {
        const affectedLayer = this.layers.find((layer) => layer.id === updates.layerId);
        if (affectedLayer) {
          this.listenForLayerFeaturesCount([affectedLayer.id], false);
        }
        this.updateCurrentViewLayerFilters(updates);
        this.changeDetectionRef.detectChanges();
      });
  }

  private listenForViewIdUpdates() {
    this.route.queryParamMap
      .pipe(
        distinctUntilChanged(),
        waitFor(this.layersReady),
        filter((queryParamMap: ParamMap) => !!queryParamMap.get(QUERY_PARAMS.VIEW_ID)),
        switchMap((queryParamMap: ParamMap) => {
          const viewId = queryParamMap.get(QUERY_PARAMS.VIEW_ID) as string;
          return this.fetchSavedView(viewId);
        }),
        takeUntil(this.destroyed),
      )
      .subscribe((savedView: FilterView | null) => {
        if (!savedView) {
          console.error('no saved filter view returned');
          return;
        }
        this.loadFilterView(savedView);
        this.changeDetectionRef.detectChanges();
      });
  }

  //   // If Sunroof is enabled, use monotone map theme so Sunroof colors stand out.
  //   private updateMapForSolar() {
  //     const sunroofLayer = this.layers.find(
  //       (layer) => layer.getId() === SUNROOF_LAYER_ID,
  //     );
  //     if (!sunroofLayer) {
  //       return;
  //     }
  //     if (this.visibilityByLayer.get(sunroofLayer)) {
  //       this.mapService.setMapStyles('silver');
  //     } else {
  //       this.mapService.setMapStyles('default');
  //     }
  //   }

  private setLayerVisibility(layer: Layer, visible: boolean, userInitiated: boolean) {
    const vbl = new Map([...this.visibilityByLayerId, [layer.id, visible]]);
    this.mapService.setVisibilityByLayer({
      visibilityByLayerId: vbl,
      userInitiated,
    });
    if (!visible) {
      this.editingByLayer.set(layer.id, false);
      return;
    }
    this.analyticsService.sendEvent(EventActionType.LAYER_ON, {
      event_category: EventCategoryType.MAP,
      event_label: layer.name,
    });
  }

  // Populate the state from FilterView saved in Spanner.
  private fetchSavedView(viewId: string): Observable<FilterView | null> {
    return this.filterViewsService.getFilterView(viewId).pipe(
      first(),
      catchError(() => {
        this.snackBar.open('Could not load saved filter view.', '', {
          duration: TOAST_DURATION_MS,
        });
        return of(null);
      }),
    );
  }

  // Populates the filters state acc to the provided filter view.
  private loadFilterView(view: FilterView) {
    const userInitiated = false;
    this.selectedViewId = view.id;
    // Notify view component of updated state.
    this.currentView = view;
    // Set filters and toggle layers accordingly.
    const filtersByLayer = extractFiltersByLayer(view);
    for (const layer of this.layers) {
      this.setLayerVisibility(layer, filtersByLayer.has(layer.id), userInitiated);
      const layerFilters = filtersByLayer.get(layer.id);
      this.layersFilterService.updateFilterMap(
        layer.id,
        layerFilters?.filters || {},
        userInitiated,
      );
      this.layersFilterService.updateIncludeInactive(
        layer.id,
        layerFilters?.includeInactiveResults || false,
        userInitiated,
      );
    }
  }

  private loadCachedFilterState(visibilityByLayer: Map<string, boolean>, filters: LayerFilters[]) {
    this.visibilityByLayerId = visibilityByLayer;
    this.updateCurrentViewLayersVisibility(visibilityByLayer);
    for (const layerFilters of filters) {
      this.updateCurrentViewLayerFilters(layerFilters);
    }
  }

  private updateCurrentViewLayersVisibility(visibilityByLayer: Map<string, boolean>) {
    this.currentView = toggleLayers(this.currentView, visibilityByLayer);
  }

  private updateCurrentViewLayerVisibility(layer: Layer, isOn: boolean) {
    this.currentView = toggleLayer(this.currentView, layer.id, isOn);
  }

  private updateCurrentViewLayerFilters(layerFilters: LayerFilters) {
    this.currentView = setFiltersForLayer(this.currentView, layerFilters);
  }

  private fetchLayerFiltersUpdates(layerId: string): Observable<LayerFilters> {
    return combineLatest([
      this.layersFilterService.getFilterMap(layerId).pipe(first()),
      this.layersFilterService.includeInactive(layerId).pipe(first()),
    ]).pipe(
      map(([filters, includeInactiveResults]: [FilterMap, boolean]) => {
        return {
          layerId,
          filters,
          includeInactiveResults,
        };
      }),
    );
  }

  // Disables the ZoomToResults button for each layer if 0 features found.
  private listenForLayerFeaturesCount(layerIds: string[], reset: boolean) {
    if (reset) {
      this.layersWithFeatures.clear();
    }

    from(layerIds)
      .pipe(
        mergeMap((layerId: string) => {
          return this.featuresCountService.getTotalCountForLayer(layerId).pipe(
            filter((totalCount: number) => totalCount !== undefined),
            switchMap((totalCount: number) => {
              const hasFeatures = totalCount > 0;
              this.layersWithFeatures.set(layerId, hasFeatures);
              return of(totalCount);
            }),
          );
        }),
      )
      .subscribe();
  }

  private findVisibleLayers(layerIdsMap: Map<string, boolean>) {
    const results = [];
    for (const value of layerIdsMap) {
      if (value[1] === true) {
        results.push(value[0]);
      }
    }
    return results;
  }

  showZoomToResultsButton(layer: Layer): ZoomDisplay {
    if (!this.configService.zoomToResultsEnabled) return {visible: false, enabled: false};

    return {
      visible: this.layersWithFeatures.has(layer.id) && this.visibilityByLayerId.get(layer.id)!,
      enabled: this.layersWithFeatures.has(layer.id) && this.layersWithFeatures.get(layer.id)!,
    };
  }

  zoomToResults(layer: Layer) {
    this.layersFilterService
      .includeInactive(layer.id)
      .pipe(first(), takeUntil(this.destroyed))
      .subscribe((includeInactiveResults: boolean) => {
        this.layersFilterService.updateIncludeInactive(
          layer.id,
          includeInactiveResults,
          true, // userInitiated = true.
          true, // globalSearch = true.
        );
      });
  }
}
