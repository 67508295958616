import {PromiseClient} from '@connectrpc/connect';
import {
  Observable,
  ReplaySubject,
  firstValueFrom,
  from, //of
} from 'rxjs';
import {Subject} from 'rxjs/internal/Subject';
import {
  take,
  takeUntil, //tap
} from 'rxjs/operators';

import {Injectable} from '@angular/core';

import {
  Layer,
  LayerStyle,
  Layer_LayerType,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {LayerService} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_connect';
import {
  GetAutocompleteResultsRequest,
  GetAutocompleteResultsResponse,
  GetLayersRequest,
  GetLayersResponse,
  GetPropertyKeysRequest,
  GetPropertyKeysResponse,
  UpdateLayerConfigRequest,
  UpdateLayerConfigResponse,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layerservice_pb';

import {
  SOLAR_INSIGHTS_LAYER_ID,
  STREETVIEW_RECENCY_LAYER_ID,
  SUNROOF_LAYER_ID,
} from '../constants/layer';
import {setsAreEqual} from '../utils/collections';
import {ApiService} from './api_service';
import {ConfigService} from './config_service';
import {LocalStorageService} from './local_storage_service';

// Max feature property value results to return.
const AUTOCOMPLETE_MAX_RESULTS = 8;

const SUNROOF_LAYER: Layer = new Layer({
  id: SUNROOF_LAYER_ID,
  name: 'Sunroof',
  layerType: Layer_LayerType.IMAGE_TILE,
});

const SOLAR_INSIGHTS_LAYER: Layer = new Layer({
  id: SOLAR_INSIGHTS_LAYER_ID,
  name: SOLAR_INSIGHTS_LAYER_ID,
  layerType: Layer_LayerType.NATIVE,
});

const STREETVIEW_RECENCY_LAYER: Layer = new Layer({
  id: STREETVIEW_RECENCY_LAYER_ID,
  name: 'Street View Recency',
  layerType: Layer_LayerType.TYPE_UNSPECIFIED,
});

/**
 * The maximum number of property value results. This number is being set to
 * one million because the intention is to fetch all possible results and
 * handle the filtering on the front end. In the future, the API should return
 * results on each keystroke. Currently, the DB is not indexed appropriately.
 */
const PROPERTY_VALUES_MAX_RESULTS = Math.pow(10, 6);

/**
 * Service for fetching layer data and listening for changes in the layer.
 */
@Injectable()
export class LayersService {
  private readonly client: PromiseClient<typeof LayerService>;
  private readonly propertyKeysByLayerId = new PropertyKeysByLayer();
  unsubscribe = new Subject<void>();

  layerById = new Map<string, Layer>();
  layers = new ReplaySubject<Layer[]>(1);
  layersReady = new ReplaySubject<void>(1);
  private readonly destroyed = new Subject<void>();

  constructor(
    private readonly apiService: ApiService,
    private readonly localStorageService: LocalStorageService,
    private readonly configService: ConfigService,
  ) {
    this.client = apiService.createLayerServiceBEClient();
    this.getAllLayersMetadata().pipe(take(1), takeUntil(this.destroyed)).subscribe();
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  setLayersReady() {
    this.layersReady.next();
  }

  onLayersReady(): Observable<void> {
    return this.layersReady.asObservable();
  }

  private setLayersMetadata(layers: Layer[]) {
    this.layers.next(layers);
  }

  private updateLayers(layers: Layer[]) {
    // Solar Insights layer replaces Sunroof layer when solar2Enabled is true.
    if (this.configService.solar2Enabled) {
      layers.push(SOLAR_INSIGHTS_LAYER);
    } else {
      layers.push(SUNROOF_LAYER);
    }
    if (this.configService.streetviewRecencyEnabled) {
      layers.push(STREETVIEW_RECENCY_LAYER);
    }
    for (const layer of layers) {
      this.layerById.set(layer.id, layer);
    }
    this.setLayersMetadata(layers);
    this.setLayersReady();
  }

  onLayersMetadataChanged(): Observable<Layer[]> {
    return this.layers.asObservable();
  }

  /**
   * Fetches all layers metadata.
   */
  getAllLayersMetadata(): Observable<Layer[]> {
    return from(
      this.apiService
        .withCallOptions<GetLayersResponse>((callOptions) =>
          this.client.getLayers(new GetLayersRequest(), callOptions),
        )
        .then(
          (response: GetLayersResponse) => {
            // Ignore original images layer.
            const layers = response.layers.filter(
              (layer: Layer): boolean => layer.layerType !== Layer_LayerType.IMAGES,
            );
            this.updateLayers(layers);
            return layers;
          },
          (error: Error) => {
            throw new Error(`Could not get Layers' metadata: ${error.message}`);
          },
        ),
    );
  }

  getLayerName(layerId: string): string | null {
    const layerMetadata = this.layerById.get(layerId);
    if (!layerMetadata) {
      // TODO(reubenn): Add some type of debug-time logging.
      // `Attempting to get a layer name for ${layerId} layer ID which doesn't
      // exist`
      return null;
    }
    return layerMetadata.name;
  }

  updateLayerStyle(layerId: string, layerStyle: LayerStyle): Observable<LayerStyle | null> {
    const request = new UpdateLayerConfigRequest({
      layerId: layerId,
      layerStyle: layerStyle,
    });
    return from(
      this.apiService
        .withCallOptions<UpdateLayerConfigResponse>((callOptions) =>
          this.client.updateLayerConfig(request, callOptions),
        )
        .then(
          (response: UpdateLayerConfigResponse) => {
            firstValueFrom(this.getAllLayersMetadata());
            return response.layerStyle || null;
          },
          (error: Error) => {
            throw new Error(
              `Could not update layer style for layer with ID '${layerId}': ${error.message}`,
            );
          },
        ),
    );
  }

  /**
   * Fetches all available keys for a layer's features' properties.
   */
  getLayerPropertyKeys(
    layerId: string,
    forceFetch: boolean,
    includeInactive: boolean,
  ): Observable<string[]> {
    if (
      this.propertyKeysByLayerId.hasPropertyKeysForLayer(layerId, includeInactive) &&
      !forceFetch
    ) {
      return this.propertyKeysByLayerId.getPropertyKeys(layerId, includeInactive);
    }
    const propertyKeysFromStorage = this.localStorageService.readLayerPropertyKeys(
      layerId,
      includeInactive,
    );
    if (propertyKeysFromStorage.length > 0) {
      this.propertyKeysByLayerId.setPropertyKeys(layerId, includeInactive, propertyKeysFromStorage);
    }
    const request = new GetPropertyKeysRequest({
      layerId: layerId,
      includeInactive: includeInactive,
    });
    this.apiService
      .withCallOptions<GetPropertyKeysResponse>((callOptions) =>
        this.client.getPropertyKeys(request, callOptions),
      )
      .then(
        (response: GetPropertyKeysResponse) => {
          if (
            setsAreEqual(new Set(propertyKeysFromStorage), new Set(response.keys)) &&
            propertyKeysFromStorage.length > 0
          ) {
            return;
          }
          this.localStorageService.writeLayerPropertyKeys(layerId, includeInactive, response.keys);
          this.propertyKeysByLayerId.setPropertyKeys(layerId, includeInactive, response.keys);
        },
        (error: Error) => {
          throw new Error(`Failed to get property keys for layer ${layerId}: ${error}`);
        },
      );
    return this.propertyKeysByLayerId.getPropertyKeys(layerId, includeInactive);
  }

  getLayerType(layerId: string): Layer_LayerType | null {
    const layerMetadata = this.layerById.get(layerId);
    if (!layerMetadata) {
      // TODO(reubenn): Add some type of debug-time logging.
      //`Attempting to get a layer type for ${layerId} layer ID which doesn't
      // exist`;
      return null;
    }
    return layerMetadata.layerType;
  }

  getLayerStyle(layerId: string): LayerStyle | null {
    const layerMetadata = this.layerById.get(layerId);
    if (!layerMetadata) {
      // TODO(reubenn): Add some type of debug-time logging.
      //`Attempting to get a layer style for ${layerId} layer ID which doesn't
      // exist`;
      return null;
    }
    return layerMetadata.layerStyle || null;
  }

  getLayerIdFromType(layerType: Layer_LayerType): string {
    for (const [layerId, layer] of this.layerById.entries()) {
      if (layer.layerType === layerType) {
        return layerId;
      }
    }
    return '';
  }

  /**
   * Fetches all available values for a layer's property key. First reads from
   * local storage and returns values if found. Then fetches and emits returned
   * values if there is a diff with what is in local storage.
   */
  getLayerPropertyValues(
    layerId: string,
    propertyKey: string,
    includeInactiveResults: boolean,
  ): Observable<string[]> {
    const propertyValues = new ReplaySubject<string[]>(1);
    const valuesFromStorage = this.localStorageService.readLayerPropertyKeyValues(
      layerId,
      propertyKey,
    );
    if (valuesFromStorage.length > 0) {
      propertyValues.next(valuesFromStorage);
    }
    // TODO(b/178563136): Investigate tokenization of property values for a
    // given property key using ST spanner.
    this.getAutocompleteResults(
      layerId,
      propertyKey,
      '',
      includeInactiveResults,
      PROPERTY_VALUES_MAX_RESULTS,
    )
      .pipe(take(1))
      .subscribe((values: string[]) => {
        if (
          setsAreEqual(new Set(valuesFromStorage), new Set(values)) &&
          valuesFromStorage.length > 0
        ) {
          return;
        }
        this.localStorageService.writeLayerPropertyKeyValues(layerId, propertyKey, values);
        propertyValues.next(values);
      });
    return propertyValues;
  }

  /**
   * Fetches property values containing a specified substring for a specified
   * property.
   */
  getAutocompleteResults(
    layerId: string,
    propertyKey: string,
    substring: string,
    includeInactive: boolean,
    maxResults?: number,
  ): Observable<string[]> {
    const request = new GetAutocompleteResultsRequest({
      layerId: layerId,
      key: propertyKey,
      substring: substring,
      includeInactive: includeInactive,
      maxResults: maxResults || AUTOCOMPLETE_MAX_RESULTS,
    });
    return from(
      this.apiService
        .withCallOptions<GetAutocompleteResultsResponse>((callOptions) =>
          this.client.getAutocompleteResults(request, callOptions),
        )
        .then(
          (response: GetAutocompleteResultsResponse) => {
            return response.values;
          },
          (error: Error) => {
            throw new Error(
              `Could not get autocomplete results for layer ${layerId}: ${error.message}`,
            );
          },
        ),
    );
  }
}

/**
 * Kinds of the displayed property keys.
 */
enum PropertyKeysKind {
  // Property keys of the active features.
  // This kind of property keys are used by default when
  // the 'Include inactive results' checkbox is not selected for a layer.
  ACTIVE_ONLY,

  // Property keys of the both active or inactive features.
  // This kind of property keys are used if the 'Include inactive results'
  // checkbox is selected for a layer.
  ACTIVE_OR_INACTIVE,
}

/**
 * A map that stores the property keys for active or inactive features by the
 * layerId. Each property key set is associated with a layer and a
 * `PropertyKeysKind` that represents either the properties of the active
 * features only (ACTIVE_ONLY) or the properties of the active or inactive
 * features (ACTIVE_OR_INACTIVE).
 */
export class PropertyKeysByLayer {
  // Each key of the map consists of a layerId and `PropertyKeysKind` value.
  private readonly propertyKeysByLayerMap = new Map<string, ReplaySubject<string[]>>();

  // Returns true if the provided layer has any property keys.
  hasPropertyKeysForLayer(layerId: string, includeInactive: boolean): boolean {
    const key = this.toKey(layerId, includeInactive);
    return this.propertyKeysByLayerMap.has(key);
  }

  // Sets the provided property keys for the layer and the includeInactive flag.
  setPropertyKeys(layerId: string, includeInactive: boolean, layerPropertyKeys: string[]) {
    const key = this.toKey(layerId, includeInactive);
    const subject = this.getPropertyKeysByKey(key);
    subject.next(layerPropertyKeys);
  }

  // Returns property keys for the specified layer and the includeInactive flag.
  getPropertyKeys(layerId: string, includeInactive: boolean): Observable<string[]> {
    const key = this.toKey(layerId, includeInactive);
    return this.getPropertyKeysByKey(key);
  }

  private getPropertyKeysByKey(key: string): ReplaySubject<string[]> {
    const subject = this.propertyKeysByLayerMap.get(key);
    if (subject) {
      return subject;
    }
    const newSubject = new ReplaySubject<string[]>(1);
    this.propertyKeysByLayerMap.set(key, newSubject);
    return newSubject;
  }

  private toKey(layerId: string, includeInactive: boolean): string {
    const propertyKind = includeInactive
      ? PropertyKeysKind.ACTIVE_OR_INACTIVE
      : PropertyKeysKind.ACTIVE_ONLY;
    return `${layerId}:${propertyKind}`;
  }
}
