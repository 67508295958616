import {Observable, ReplaySubject, Subject, combineLatest, firstValueFrom, merge} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';

import {Injectable, OnDestroy, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

import {Layer} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';

import {QUERY_PARAMS} from '../constants/paths';
import {FilterMap, FilterMapByLayerId, FilterUpdate, LayerFilters} from '../typings/filter';
import {VisibilityByLayerUpdate} from '../typings/map';
import {AppService} from './app_service';
import {ConfigService} from './config_service';
import {LayersService} from './layers_service';
import {LocalStorageService} from './local_storage_service';
import {MapService} from './map_service';

/**
 * Service for handling all layer filtering related tasks.
 */
@Injectable()
export class LayersFilterService implements OnDestroy {
  // A map to the replay subject for each layers associated filters.
  // When a filter is added or removed, the relative replay subject will emit.
  private readonly filterMapByLayerIdUpdates: {
    [layerKey: string]: ReplaySubject<FilterMap>;
  } = {};
  private readonly filterMapByLayerId: FilterMapByLayerId = {};
  // Will emit a layer ID when a filter updates, indicating the layer has
  // had filters updated.
  private readonly filterUpdates = new Subject<FilterUpdate>();
  private readonly includeInactiveByLayerIdUpdates = new Map<string, ReplaySubject<boolean>>();
  private readonly destroyed = new Subject<void>();

  // Emits an initial state of filters loaded from local storage.
  // Not to be used for continuous updates.
  readonly layerFiltersInitialState = new ReplaySubject<LayerFilters[]>(1);

  constructor(
    private readonly appService: AppService,
    private readonly configService: ConfigService,
    private readonly localStorageService: LocalStorageService,
    private readonly mapService: MapService,
    private readonly layersService: LayersService,
    private readonly route: ActivatedRoute,
    private readonly sanitizer: DomSanitizer,
  ) {
    merge(this.mapService.getMapDestroyed(), this.appService.getWindowUnloadEvent())
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.saveFilters();
        this.saveIncludeInactive();
      });
    combineLatest([this.layersService.onLayersMetadataChanged(), this.route.queryParamMap])
      .pipe(take(1), takeUntil(this.destroyed))
      .subscribe(([layers, params]) => {
        const safeFilterStateString = this.sanitizer.sanitize(
          SecurityContext.URL,
          params.get(QUERY_PARAMS.FILTER_STATE),
        );
        if (!safeFilterStateString || !this.configService.filterDeeplinksEnabled) {
          // Read filter maps and include inactives per layer from local storage.
          const savedFilterMap = this.localStorageService.readLayerFilters();
          const savedIncludeInactives = this.localStorageService.readIncludeInactive();
          this.initFilterState(layers, savedFilterMap, savedIncludeInactives);
        } else {
          // Read filter maps from URL.
          const urlFilterMap = this.readLayerFiltersFromURL(safeFilterStateString);
          // TODO (b/370413379): Should add support for storing include inactive state in URL, currently reading from localStorage.
          const savedIncludeInactives = this.localStorageService.readIncludeInactive();
          this.initFilterState(layers, urlFilterMap, savedIncludeInactives);
          this.setUrlLayersToVisible(safeFilterStateString);
        }
      });
  }

  ngOnDestroy(): void {
    this.saveFilters();
    this.saveIncludeInactive();
    this.destroyed.next();
  }

  private initFilterState(
    layers: Layer[],
    savedFilterMap: FilterMapByLayerId,
    savedIncludeInactives: Map<string, boolean>,
  ) {
    const layerFilters = [];
    for (const layer of layers) {
      const layerId = layer.id;
      const filterMap = savedFilterMap[layerId] || {};
      const includeInactive = !!savedIncludeInactives.get(layerId);
      this.setFilterMapForLayer(layerId, filterMap);
      this.setIncludeInactiveForLayer(layerId, includeInactive);
      // Setting global search to false when the filters init which means
      // that the feature query will be bounded by the map coordinates and the
      // map will not reposition.
      this.filterUpdates.next({
        layerId,
        globalSearch: false,
        userInitiated: false,
      });
      layerFilters.push({
        layerId,
        filters: filterMap,
        includeInactiveResults: includeInactive,
      });
    }
    this.layerFiltersInitialState.next(layerFilters);
  }

  // Set all layers mentioned in the URL to be visible.
  private setUrlLayersToVisible(filterStateString: string) {
    const visibilityByLayerId = new Map<string, boolean>();
    const layerStrings = filterStateString.split('.').slice(0, -1);
    for (const layerString of layerStrings) {
      const layerId = layerString.split('!')[0];
      visibilityByLayerId.set(layerId, true);
    }
    const visibilityByLayerUpdate: VisibilityByLayerUpdate = {
      visibilityByLayerId,
      userInitiated: false,
    };
    this.mapService.setVisibilityByLayer(visibilityByLayerUpdate);
  }

  private readLayerFiltersFromURL(filterStateString: string): FilterMapByLayerId {
    const filterMapByLayerId: FilterMapByLayerId = {};
    const layerStrings = filterStateString.split('.').slice(0, -1);
    for (const layerString of layerStrings) {
      const layerId = layerString.split('!')[0];
      const filterStringsString = layerString.split('!')[1];
      if (filterStringsString.length > 0) {
        const filterStrings = filterStringsString.split(',');
        const filterMap: FilterMap = {};
        for (const filterString of filterStrings) {
          const filterName = filterString.split('-')[0];
          const valueStrings = filterString.split('-')[1].split('*');
          const values = new Set<string>();
          for (const value of valueStrings) {
            values.add(value);
          }
          filterMap[filterName] = values;
        }
        filterMapByLayerId[layerId] = filterMap;
      }
    }
    return filterMapByLayerId;
  }

  private async saveIncludeInactive() {
    const map = new Map<string, boolean>();
    for (const [layerId, includeInactiveUpdate] of this.includeInactiveByLayerIdUpdates) {
      const includeInactive = await firstValueFrom(includeInactiveUpdate.pipe(take(1)));
      map.set(layerId, includeInactive);
    }
    this.localStorageService.writeIncludeInactive(map);
  }

  private saveFilters() {
    this.localStorageService.writeLayerFilters(this.filterMapByLayerId);
  }

  /**
   * Returns an observable that emits a layer key when the filters of a layer
   * have changed and the selection is complete
   */
  layerFiltersUpdated(): Observable<FilterUpdate> {
    return this.filterUpdates.asObservable();
  }

  updateIncludeInactive(
    layerId: string,
    includeInactive: boolean,
    userInitiated: boolean,
    globalSearch = false,
  ) {
    this.setIncludeInactiveForLayer(layerId, includeInactive);
    // Emit a layer filter updated event which will refetch a layer.
    this.filterUpdates.next({
      layerId,
      globalSearch: globalSearch,
      userInitiated,
    });
    this.saveIncludeInactive();
  }

  /**
   * Returns an observable that emits when include inactives checkbox is
   * toggled for a particular layer.
   */
  includeInactive(layerId: string): Observable<boolean> {
    if (!this.includeInactiveByLayerIdUpdates.has(layerId)) {
      this.includeInactiveByLayerIdUpdates.set(layerId, new ReplaySubject<boolean>(1));
    }
    return this.includeInactiveByLayerIdUpdates.get(layerId)!.asObservable();
  }

  /**
   * Returns an observable that emits a filter dictionary for the given
   * layer key anytime an update occurs
   * @param layerId: the identifying string of a layer
   */
  getFilterMap(layerId: string): Observable<FilterMap> {
    if (!this.filterMapByLayerIdUpdates[layerId]) {
      this.filterMapByLayerIdUpdates[layerId] = new ReplaySubject<FilterMap>(1);
    }
    return this.filterMapByLayerIdUpdates[layerId].asObservable();
  }

  getAutocompleteResults$(
    layerId: string,
    name: string,
    substring: string,
    includeInactive: boolean,
    maxResults: number,
  ): Observable<string[]> {
    return this.layersService.getAutocompleteResults(
      layerId,
      name,
      substring,
      includeInactive,
      maxResults,
    );
  }

  /**
   * Adds a filter to a layer.
   * @param name: name of filter to add to layer.
   * @param value: value associated with filter name.
   */
  addFilter(layerId: string, name: string, valueSet: Set<string>): void {
    const newFilterMap: FilterMap = {
      ...this.filterMapByLayerId[layerId],
      [name]: new Set(valueSet),
    };
    const userInitiated = true;
    this.updateFilterMap(layerId, newFilterMap, userInitiated);
  }

  /**
   * Removes a filter from a layer.
   * @param name: name of filter to remove from layer.
   */
  removeFilter(layerId: string, name: string): void {
    const newFilterMap: FilterMap = {...this.filterMapByLayerId[layerId]};
    delete newFilterMap[name];
    const userInitiated = true;
    this.updateFilterMap(layerId, newFilterMap, userInitiated);
  }

  updateFilterMap(layerId: string, filterMap: FilterMap, userInitiated: boolean) {
    this.setFilterMapForLayer(layerId, filterMap);
    this.filterUpdates.next({layerId, globalSearch: true, userInitiated});
    this.saveFilters();
  }

  private setFilterMapForLayer(layerId: string, filters: FilterMap) {
    this.filterMapByLayerId[layerId] = filters;
    if (!this.filterMapByLayerIdUpdates[layerId]) {
      this.filterMapByLayerIdUpdates[layerId] = new ReplaySubject(1);
    }
    this.filterMapByLayerIdUpdates[layerId].next(filters);
  }

  private setIncludeInactiveForLayer(layerId: string, includeInactive: boolean) {
    if (!this.includeInactiveByLayerIdUpdates.has(layerId)) {
      this.includeInactiveByLayerIdUpdates.set(layerId, new ReplaySubject<boolean>(1));
    }
    this.includeInactiveByLayerIdUpdates.get(layerId)!.next(includeInactive);
  }
}
