import {ChangeDetectorRef, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import {UploadDialogData} from '../../typings/upload';

/**
 * Component for rendering the dialog displaying the upload form.
 */
@Component({
  selector: 'upload-form-dialog',
  templateUrl: './upload_form_dialog.ng.html',
  styleUrls: ['./upload_form_dialog.scss'],
})
export class UploadFormDialog {
  headerText = 'Upload';
  dialogData: UploadDialogData = {};

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly dialogRef: MatDialogRef<UploadFormDialog>,
    @Inject(MAT_DIALOG_DATA) public data: UploadDialogData,
  ) {
    if (data) {
      this.dialogData = data;
    }
  }

  updateHeader(newHeaderText: string) {
    this.headerText = newHeaderText;
    this.cd.detectChanges();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
