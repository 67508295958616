import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {
  StorageReference,
  UploadTask,
  getStorage,
  ref,
  uploadBytesResumable,
} from 'firebase/storage';
import {
  BehaviorSubject,
  Observable,
  OperatorFunction,
  ReplaySubject,
  Subject,
  combineLatest,
  firstValueFrom,
  forkJoin,
  from,
  merge,
  of,
  pipe,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  last,
  map,
  mergeAll,
  mergeMap,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {v4 as uuidv4} from 'uuid';

import {Injectable} from '@angular/core';

import {
  Feature,
  FeatureIdMap,
  Property,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Geometry, Point} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';
import {
  AnnotatedImage,
  ImageAnnotation,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_annotation_pb';
import {
  Image,
  ImageLocation,
  ImageMetadata,
  Image_ImageState,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {ASSETS_LAYER_ID} from '../constants/layer';
import {NonFormPropertyName, PropertyKey} from '../constants/properties';
import {IDB_UPLOADS_STORE_NAME} from '../constants/storage';
import {FORM_DIALOG_WIDTH} from '../styles/constants';
import {Annotation, AnnotationInfo} from '../typings/annotations';
import {
  AnnotationForDb,
  OfflineProperty,
  PendingUploadGroup,
  PendingUploadGroupForDb,
  UploadDialogData,
  UploadForm,
  UploadState,
} from '../typings/upload';
import {UploadFormDialog} from '../upload/upload_form_dialog/upload_form_dialog';
import {getEquipmentId, getFeederId} from '../utils/feature';
import {AnalyticsService, EventActionType, EventCategoryType} from './analytics_service';
import {AnnotationsService} from './annotations_service';
import {AuthService} from './auth_service';
import {ConfigService} from './config_service';
import {DialogService} from './dialog_service';
import {FeaturesService} from './features_service';
import {GoogleMapsService} from './google_maps_service';
import {IndexedDBService} from './indexed_db_service';
import {PendingUploadQueueService} from './pending_upload_queue_service';
import {PhotosService} from './photos_service';

/**
 * Information about uploaded image.
 */
export declare interface UploadedImage {
  // ID used to identify the file before it got uploaded. At the moment this
  // will default to file name.
  fileId: string;
  image: Image;
}

/**
 * The current state of an upload.
 */
export interface UploadResponseMetadata {
  uploadState: keyof typeof UploadState;
  bytesTransferred: number | null;
  image: Image | null;
}

/**
 * This is the response to a pending upload attempt. The request has a form and
 * files, while the response is a map from file to image and upload state.
 */
export type UploadResponse = Map<File, UploadResponseMetadata>;

/**
 * The response from updating a defect.
 */
export interface UpdateDefectResponse {
  feature: Feature | null;
  images: Image[];
}

/**
 * An upload group has the uplaoding count and a map of id to upload metadata.
 * Used internally to keep track of different groups of uploads.
 */
interface UploadGroup {
  // A count of uploads that are not completed.
  uploadingCount: number;
  // Maps individual file keys to their upload state and file. The key used is a
  // timestamp.
  uploadMetadataByKey: Map<string, UploadMetadata>;
}

/**
 * A file, its associated uploaded image, and an upload state. The image is
 * expected to be null unless the uploads state is success.
 */
interface UploadMetadata {
  uploadState: keyof typeof UploadState;
  bytesTransferred: number | null;
  file: File;
  // Returned from the BE on successful image upload.
  image: Image | null;
}

// @see https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
const GEOLOCATION_OPTIONS = {
  enableHighAccuracy: true,
};

// @see https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
const GEOLOCATION_ERROR_MESSAGE_BY_CODE = new Map<number, string>([
  [1, "The page doesn't have permission to return geolocation"],
  [2, 'At least one internal source of position returned an internal error'],
  [3, 'It took too long to retrieve position information'],
]);

/**
 * Service for uploading images.
 */
@Injectable({providedIn: 'root'})
export class UploadService {
  readonly pendingUploadCount$ = new ReplaySubject<number>(1);
  // The ID of an upload group. Group ID will be incremented with every
  // upload-group request.
  private groupId = 0;
  // The ID of the watch position handler function. This ID is used to clear
  // the watcher with clearWatch(ID).
  // @see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/clearWatch
  private watchPositionId = 0;
  // The image location from the browser.
  private locationFromBrowser: LatLng | null = null;
  // Whether images should be uploaded to Firebase instead of Scotty.
  private gcpUploadEnabled = false;
  // Parameters with which upload form was opened in a dialog.
  private uploadDialogData: UploadDialogData | null = null;
  // Whether the upload form was opened in a dialog instead of a page.
  private uploadFormOpenedInDialog: boolean = false;
  // Whether upload dialog is interrupted/closed in order to navigate to other pages as a part of
  // the upload flow.
  private uploadDialogInterrupted = false;
  // Emits whether upload dialog should be re-opened to resume filling the upload form.
  private reopenUploadDialog = new BehaviorSubject<boolean>(false);
  // IndexedDB object store that holds uploads data.
  private readonly storeName = IDB_UPLOADS_STORE_NAME;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly annotationsService: AnnotationsService,
    private readonly authService: AuthService,
    private readonly configService: ConfigService,
    private readonly dialogService: DialogService,
    private readonly featuresService: FeaturesService,
    private readonly googleMapsService: GoogleMapsService,
    private readonly indexedDBService: IndexedDBService,
    private readonly pendingUploadQueueService: PendingUploadQueueService,
    private readonly photosService: PhotosService,
  ) {
    this.gcpUploadEnabled = this.configService.gcpUploadEnabled;
    this.init();
  }

  init() {
    this.updatePendingUploadCount();
  }

  setLocationFromBrowser(location: LatLng | null) {
    this.locationFromBrowser = location;
  }

  getLocationFromBrowser(): LatLng | null {
    return this.locationFromBrowser;
  }

  getUploadDialogData(): UploadDialogData | null {
    return this.uploadDialogData;
  }

  setUploadDialogData(dialogData: UploadDialogData) {
    this.uploadDialogData = dialogData;
  }

  isUploadFormOpenedInDialog(): boolean {
    return this.uploadFormOpenedInDialog;
  }

  setUploadFormOpenedInDialog(uploadFormOpenedInDialog: boolean) {
    this.uploadFormOpenedInDialog = uploadFormOpenedInDialog;
  }

  resetUploadDialogInfo() {
    this.uploadFormOpenedInDialog = false;
    this.uploadDialogData = null;
    this.uploadDialogInterrupted = false;
    this.reopenUploadDialog.next(false);
  }

  isUploadDialogInterrupted(): boolean {
    return this.uploadDialogInterrupted;
  }

  setUploadDialogInterrupted(uploadDialogInterrupted: boolean) {
    this.uploadDialogInterrupted = uploadDialogInterrupted;
  }

  getReopenUploadDialog(): BehaviorSubject<boolean> {
    return this.reopenUploadDialog;
  }

  setToReopenUploadDialog() {
    this.reopenUploadDialog.next(true);
  }

  renderUploadDialog(dialogData?: UploadDialogData) {
    this.dialogService.render<UploadFormDialog, boolean>(UploadFormDialog, {
      maxWidth: FORM_DIALOG_WIDTH,
      width: FORM_DIALOG_WIDTH,
      data: dialogData,
      panelClass: 'form-dialog',
    });
  }

  /**
   * Given an image name, returns a unique filepath for image storage in GCS.
   */
  buildGCSImagePath(filename: string): string {
    const date = new Date().toISOString().split('T')[0]; // UTC time as YYYY-MM-DD
    const newUuid = uuidv4();
    return `user-uploads/${date}/${newUuid}_${filename}`;
  }

  /**
   * Needed for unit test spy, can't mock uploadBytesResumable directly.
   * See https://github.com/jasmine/jasmine/issues/1414.
   */
  uploadToGCS(ref: StorageReference, data: Blob | Uint8Array | ArrayBuffer): UploadTask {
    return uploadBytesResumable(ref, data);
  }

  /**
   * Returns an observable for files mapped to their current status and (if
   * successfully uploaded) their resulting image.
   *
   * Intermediate values on the result will contain progress for each file. The
   * result will complete upon total success or failure.
   */
  upload(
    pendingUploadGroup: PendingUploadGroup,
    mapSelectionLocation: LatLng | null,
  ): Observable<UploadedImage[]> {
    if (pendingUploadGroup.files.length === 0) {
      return throwError(() => new Error('Upload failed due to missing files.'));
    }
    const uploadsByName: Map<string, Subject<UploadedImage>> = new Map();
    return from(
      this.authService.getAuthHeaders().then(() => {
        for (const [, file] of pendingUploadGroup.files.entries()) {
          const location = mapSelectionLocation || pendingUploadGroup.locationFromBrowser || null;
          const imageMetadata = this.createImageMetadata(
            file,
            pendingUploadGroup.uploadForm,
            location,
          );
          const firebaseStorage = getStorage();
          const uploadFilePath = this.buildGCSImagePath(file.name);
          const storageRef = ref(firebaseStorage, uploadFilePath);
          const uploadTask = this.uploadToGCS(storageRef, file);
          uploadsByName.set(uploadFilePath, new Subject<UploadedImage>());
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused');
                  break;
                case 'running':
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              const message = `Error uploading image: ${error}`;
              console.error(message);
              uploadsByName.get(uploadFilePath)?.complete();
              throw new Error(message);
            },
            () => {
              console.log(`uploaded ${uploadFilePath} to Firebase Cloud Storage`);
              const image = this.photosService.addGCSImage(imageMetadata, storageRef.toString());
              image.subscribe({
                next: (image) => {
                  const update = uploadsByName.get(uploadFilePath);
                  if (update && image) {
                    update.next({fileId: uploadFilePath, image});
                    update.complete();
                  }
                },
                error: (error) => {
                  const message = `Error uploading image metadata: ${error}`;
                  console.error(message);
                  uploadsByName.get(uploadFilePath)?.complete();
                },
              });
            },
          );
        }
        const uploadUpdates = Array.from(uploadsByName.values());
        return uploadUpdates.length > 0 ? forkJoin(uploadUpdates) : of([]);
      }),
    ).pipe(mergeAll());
  }

  /**
   * Implementation of upload tied back to blobstore in g3.
   * Returns the uploaded images.
   * TODO(b/349411028): deprecate this once the GCS
   * uploads are live.
   */
  uploadG3(
    pendingUploadGroup: PendingUploadGroup,
    mapSelectionLocation: LatLng | null,
  ): Observable<UploadedImage[]> {
    if (pendingUploadGroup.files.length === 0) {
      throw new Error('Upload failed due to missing files.');
    }
    const groupId = this.createGroupId();
    const uploadGroup: UploadGroup = {
      uploadingCount: pendingUploadGroup.files.length,
      uploadMetadataByKey: new Map<string, UploadMetadata>(),
    };
    const uploadUpdates: Observable<UploadedImage>[] = [];
    for (const [index, file] of pendingUploadGroup.files.entries()) {
      const uploadMetadata: UploadMetadata = {
        uploadState: UploadState.UPLOADING,
        bytesTransferred: 0,
        file,
        image: null,
      };
      const id = String(Number(groupId) + (index + 1));
      uploadGroup.uploadMetadataByKey.set(id, uploadMetadata);
      const location = mapSelectionLocation || pendingUploadGroup.locationFromBrowser || null;
      const imageMetadata = this.createImageMetadata(file, pendingUploadGroup.uploadForm, location);

      // experimental: upload bytes
      uploadUpdates.push(
        this.photosService.addImageWithUpload(file, id, imageMetadata).pipe(
          filter((uploadedImage) => !!uploadedImage),
          map((uploadedImage: Image | null): UploadedImage => {
            return {fileId: file.name, image: uploadedImage!};
          }),
        ),
      );
    }
    return uploadUpdates.length > 0 ? forkJoin(uploadUpdates) : of([]);
  }

  /**
   * Creates the defect and assigns the provided information and imagery to it.
   */
  createDefect(
    uploadForm: UploadForm,
    images: Image[],
    relatedAsset: Feature | null,
  ): Observable<Feature | null> {
    const tags = [...uploadForm.tags].map((tag: string) => new Tag({name: tag}));
    const imageLocation = images[0].location!.location!;
    const imageIds = images.map((image: Image) => image.id);
    const formProperties = uploadForm.extraProperties || [];
    let relatedAssetLocation = null;
    if (relatedAsset?.geometry?.geometry.case === 'point') {
      relatedAssetLocation = relatedAsset?.geometry?.geometry.value.location;
    }
    const relatedAssetId = relatedAsset?.id || '';
    const location = relatedAssetLocation || imageLocation;
    const address = this.googleMapsService.getAddressFromLatLng({
      lat: location.latitude,
      lng: location.longitude,
    });
    const assetProperties = address.pipe(this.imageAssetProperties(relatedAsset));
    return assetProperties.pipe(
      this.createDefectFeature(location, imageIds, tags, relatedAssetId, formProperties),
    );
  }

  /**
   * Updates the defect by assigning the provided information and imagery to it.
   * Please note that this function mutates the input defect object.
   */
  updateDefect(
    defect: Feature,
    uploadForm: UploadForm,
    images: Image[],
    relatedAsset: Feature | null,
    mapLocation: LatLng | null,
  ): Observable<UpdateDefectResponse> {
    const tags = [...uploadForm.tags].map((tag: string) => new Tag({name: tag}));
    defect.tags = tags;
    const imageLocation = images[0].location!.location!;
    const imageIds = images.map((image: Image) => image.id);
    let relatedAssetLocation = null;
    if (relatedAsset?.geometry?.geometry.case === 'point') {
      relatedAssetLocation = relatedAsset?.geometry?.geometry.value.location;
    }
    const newDefectLocation = relatedAssetLocation || mapLocation;
    if (newDefectLocation) {
      defect.geometry = new Geometry({
        geometry: {
          case: 'point',
          value: new Point({location: newDefectLocation}),
        },
      });
    }
    const relatedAssetId = relatedAsset?.id || '';
    const formProperties = uploadForm.extraProperties || [];
    const location = newDefectLocation || imageLocation;
    const address = this.googleMapsService.getAddressFromLatLng({
      lat: location.latitude,
      lng: location.longitude,
    });
    const assetProperties = address.pipe(this.imageAssetProperties(relatedAsset));
    const imageUpdate = this.updateImages(images, uploadForm, location);
    const defectUpdate = assetProperties.pipe(
      this.updateDefectFeature(defect, imageIds, relatedAssetId, formProperties),
    );

    return forkJoin({feature: defectUpdate, images: imageUpdate});
  }

  /**
   * Removes the provided defect.
   */
  deleteDefect(defect: Feature): Observable<null> {
    return this.featuresService.updateDefect(defect, '', []).pipe(map(() => null));
  }

  /**
   * Saves the upload information to the pending queue so that it can be retried
   * in case of failure.
   */
  saveImageGroupToPendingQueue(pendingUploadGroup: PendingUploadGroup): Observable<number> {
    return this.uploadOffline(
      pendingUploadGroup.uploadForm,
      pendingUploadGroup.files,
      pendingUploadGroup.annotationsPerFile || [],
    ).pipe(
      map((uploadKey: IDBValidKey): number => uploadKey as number),
      catchError((error: Error) => {
        const message = `Failed to save upload to pending queue: ${error}`;
        console.error(message);
        throw new Error(message);
      }),
    );
  }

  /**
   * Deletes provided images from photos backend.
   */
  deleteImages(imagesToDelete: Image[]): Observable<Image[]> {
    const updates = [];
    for (const image of [...imagesToDelete]) {
      updates.push(this.photosService.deleteImage(image));
    }
    return updates.length > 0 ? forkJoin(updates) : of([]);
  }

  /**
   * Saves pending annotation state for new files (edit operation is handled in
   * annotations editor 'save' scenario).
   */
  saveAnnotations(
    newImages: UploadedImage[],
    referenceIdByFileName?: Map<string, string>,
  ): Observable<AnnotatedImage | null> {
    if (newImages.length === 0) {
      return of(null);
    }
    const saveOperations = newImages.map(
      (entry: UploadedImage): Observable<AnnotatedImage | null> => {
        const refId = this.gcpUploadEnabled ? entry.image.originalFileName : entry.fileId;
        return this.annotationsService.saveAnnotationsForImage(
          entry.image.id,
          referenceIdByFileName?.get(refId) || entry.image.id,
        );
      },
    );
    return merge(...saveOperations).pipe(
      catchError((error: Error) => {
        // TODO(halinab): reconsider the whole 3-part defect upload process
        // (1. upload images -> 2. create defect + relations -> 3. save
        // annotations) and either notify the user about the individually failed
        // stages or treat the whole upload as a single transaction.
        console.error(`Failed to save annotations. ${error.message}`);
        return of(null);
      }),
    );
  }

  private updateImages(
    images: Image[],
    uploadForm: UploadForm,
    location: LatLng,
  ): Observable<Image[]> {
    const updates$ = [];
    const tags = [...uploadForm.tags].map((tag: string) => new Tag({name: tag}));
    for (const image of images) {
      image.tags = tags;
      image.location!.location = location;
      image.state = Image_ImageState.ACTIVE;
      updates$.push(this.photosService.updateImage(image));
    }
    return forkJoin([...updates$]);
  }

  searchAssociatedAsset(externalId: string): Observable<Feature | null> {
    return this.featuresService.getFeatureIds([externalId]).pipe(
      mergeMap((mappings: FeatureIdMap[]) => {
        const ids = mappings.map((m) => m.id);
        if (ids.length !== 1) {
          throw new Error(`Found ${ids.length} feature IDs for external ID ${externalId}`);
        }
        return this.featuresService.getFeature(ASSETS_LAYER_ID, ids[0], /*forceFetch=*/ false);
      }),
      catchError((error: Error) => {
        console.error(error);
        return of(null);
      }),
    );
  }

  private createImageMetadata(
    file: File,
    uploadForm: UploadForm,
    location: LatLng | null,
  ): ImageMetadata {
    const tags = [...uploadForm.tags].map((tag: string) => new Tag({name: tag}));
    const imageLocation = location ? new ImageLocation({location}) : undefined;
    return new ImageMetadata({
      originalFileName: file.name,
      name: file.name,
      imageLocation,
      tags,
    });
  }

  uploadOffline(
    uploadForm: UploadForm,
    files: File[],
    annotationsPerFile: Annotation[][],
  ): Observable<IDBValidKey> {
    const pendingUploadGroupForDb = this.serialize({
      uploadForm,
      files,
      uploadedAt: new Date(),
      locationFromBrowser: this.getLocationFromBrowser(),
      annotationsPerFile,
    });
    return this.uploadSpaceAvailable(pendingUploadGroupForDb).pipe(
      mergeMap(() => {
        return this.indexedDBService.addItem<PendingUploadGroupForDb>(
          this.storeName,
          pendingUploadGroupForDb,
        );
      }),
      tap(() => {
        this.updatePendingUploadCount();
      }),
    );
  }

  getAllPendingUploads(): Observable<Map<number, PendingUploadGroup>> {
    return combineLatest([
      this.indexedDBService.getAllItems(this.storeName),
      this.indexedDBService.getAllItemKeys(this.storeName),
    ]).pipe(
      map(([pendingUploadGroupsForDb, keys]): [PendingUploadGroupForDb[], number[]] => {
        return [pendingUploadGroupsForDb as PendingUploadGroupForDb[], keys as number[]];
      }),
      map(([pendingUploadGroupsForDb, keys]: [PendingUploadGroupForDb[], number[]]) => {
        const pendingUploadGroupByKey = new Map<number, PendingUploadGroup>();
        for (let i = 0; i < pendingUploadGroupsForDb.length; i++) {
          const pendingUploadGroup = this.deserialize(pendingUploadGroupsForDb[i], keys[i]);
          if (pendingUploadGroup) {
            pendingUploadGroupByKey.set(keys[i], pendingUploadGroup);
          }
        }
        return pendingUploadGroupByKey;
      }),
    );
  }

  updatePendingUpload(
    pendingUploadGroup: PendingUploadGroup,
    key: number,
  ): Observable<IDBValidKey> {
    const pendingUploadGroupForDb = this.serialize(pendingUploadGroup);
    return this.indexedDBService.putItem<PendingUploadGroupForDb>(
      this.storeName,
      pendingUploadGroupForDb,
      key,
    );
  }

  deletePendingUpload(key: number): Observable<void> {
    return this.indexedDBService.deleteItem(this.storeName, key).pipe(
      tap(() => {
        this.updatePendingUploadCount();
      }),
    );
  }

  getPendingUpload(key: number): Observable<PendingUploadGroup | null> {
    return this.indexedDBService
      .getItem(this.storeName, key)
      .pipe(
        map((data): PendingUploadGroup | null =>
          this.deserialize(data as PendingUploadGroupForDb, key),
        ),
      );
  }

  async updatePendingUploadCount() {
    const count = await firstValueFrom(this.indexedDBService.countAllItems(this.storeName));
    this.pendingUploadCount$.next(count);
  }

  getPendingUploadCount(): Observable<number> {
    return this.pendingUploadCount$.asObservable();
  }

  /**
   * Actual execution of upload. Uploads provided images and related information
   * collected from user by either updating the already existing defect (if
   * defect object is provided) or creating the new one and associating it with
   * the asset if such link was provided.
   */
  triggerUploadImagesOfAsset(
    uploadData: PendingUploadGroup,
    location: LatLng | null,
    relatedAsset: Feature | null,
    formName: string,
    existingImages: Image[],
    defect: Feature | null,
    referenceIdByFileName: Map<string, string>,
    uploadIdInIndexedDB: number | null,
  ) {
    this.uploadImagesOfAsset(
      uploadData,
      location,
      relatedAsset,
      formName,
      existingImages,
      defect,
      referenceIdByFileName,
      uploadIdInIndexedDB,
    )
      .pipe(take(1))
      .subscribe();
  }

  /**
   * Uploads provided images and related information collected from user by
   * either updating the already existing defect (if defect object is provided)
   * or creating the new one and associating it with the asset if such link was
   * provided.
   */
  uploadImagesOfAsset(
    uploadData: PendingUploadGroup,
    location: LatLng | null,
    relatedAsset: Feature | null,
    formName: string,
    existingImages: Image[],
    existingDefect: Feature | null,
    referenceIdByFileName: Map<string, string>,
    uploadIdInIndexedDB: number | null,
  ): Observable<UploadState> {
    const isEdit = existingDefect !== null;
    const operation = isEdit
      ? this.updateImageGroupOfAsset(
          uploadData,
          location,
          relatedAsset,
          formName,
          existingImages,
          existingDefect,
          referenceIdByFileName,
        )
      : this.createImageGroupOfAsset(
          uploadData,
          location,
          relatedAsset,
          formName,
          existingImages,
          referenceIdByFileName,
          uploadIdInIndexedDB,
        );
    return operation.pipe(
      tap(() => {
        this.annotationsService.clearPendingState();
      }),
    );
  }

  /**
   * Uploads the user provided images with annotations, creates the defect
   * linked to provided asset, assigns images to the defect.
   */
  private createImageGroupOfAsset(
    uploadData: PendingUploadGroup,
    location: LatLng | null,
    relatedAsset: Feature | null,
    formName: string,
    existingImages: Image[],
    referenceIdByFileName: Map<string, string>,
    uploadIdInIndexedDB: number | null,
  ): Observable<UploadState> {
    const uploadId = this.saveImageGroupToPendingQueue(uploadData);

    return uploadId.pipe(
      tap((id: number) => {
        this.uploadState(id, UploadState.PENDING, uploadData, null);
      }),
      switchMap(
        (id: number): Observable<number> =>
          uploadIdInIndexedDB !== null && uploadIdInIndexedDB > 0
            ? this.deletePendingUpload(uploadIdInIndexedDB).pipe(map(() => id))
            : of(id),
      ),
      switchMap(
        (id: number): Observable<[Feature | null, UploadState, number]> =>
          this.uploadNewImages(uploadData, location, formName).pipe(
            catchError(() => {
              return of([]);
            }),
            this.processNewUpload(uploadData, relatedAsset, existingImages, referenceIdByFileName),
            map((defect: Feature | null): [Feature | null, UploadState, number] => [
              defect,
              defect !== null ? UploadState.SUCCEEDED : UploadState.FAILED_INCOMPLETE,
              id,
            ]),
            tap(([defect, uploadState, id]) => {
              this.uploadState(id, uploadState, uploadData, defect);
            }),
          ),
      ),
      switchMap(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([_, uploadState, id]): Observable<UploadState> =>
          uploadState === UploadState.SUCCEEDED
            ? this.deletePendingUpload(id).pipe(map(() => uploadState))
            : of(uploadState),
      ),
    );
  }

  /**
   * Uploads the user provided images with annotations, updates the defect
   * linked to provided asset, assigns images to the defect. Deleting all images
   * that belong to image group would result in image group being deleted.
   */
  private updateImageGroupOfAsset(
    uploadData: PendingUploadGroup,
    location: LatLng | null,
    relatedAsset: Feature | null,
    formName: string,
    existingImages: Image[],
    defect: Feature,
    referenceIdByFileName: Map<string, string>,
  ): Observable<UploadState> {
    let operation: Observable<UploadState> = of(UploadState.PENDING);
    if (!defect) {
      operation = of(UploadState.FAILED_INCOMPLETE);
    }
    if (defect) {
      this.uploadState(null, UploadState.PENDING, uploadData, defect);
      // If all the defect images have been removed, remove the defect.
      // TODO(halinab): make the image group removal more explicit by alerting
      // the user.
      if (uploadData.files.length === 0 && existingImages.length === 0) {
        operation = this.deleteDefect(defect).pipe(
          map((): boolean => true),
          catchError((error: Error): Observable<boolean> => {
            console.error(`Failed to delete image group with ID ${defect?.id || 'N/A'}. ${error}`);
            return of(false);
          }),
          map(
            (isSuccess: boolean): UploadState =>
              isSuccess ? UploadState.REMOVED : UploadState.EDIT_FAILED,
          ),
        );
      } else {
        operation = this.uploadNewImages(uploadData, location, formName).pipe(
          this.processEditUpload(
            defect,
            location,
            uploadData,
            relatedAsset,
            existingImages,
            referenceIdByFileName,
          ),
          map(
            (defect: Feature | null): UploadState =>
              defect !== null ? UploadState.EDIT_SUCCEEDED : UploadState.EDIT_FAILED,
          ),
        );
      }
    }
    return operation.pipe(
      tap((uploadState: UploadState) => {
        this.uploadState(null, uploadState, uploadData, defect);
      }),
    );
  }

  private processNewUpload(
    uploadData: PendingUploadGroup,
    relatedAsset: Feature | null,
    existingImages: Image[],
    referenceIdByFileName: Map<string, string>,
  ): OperatorFunction<UploadedImage[], Feature | null> {
    return pipe(
      switchMap((newImages: UploadedImage[]): Observable<UploadedImage[]> => {
        return this.saveAnnotations(newImages, referenceIdByFileName).pipe(
          map((): UploadedImage[] => newImages),
        );
      }),
      switchMap((newImages: UploadedImage[]): Observable<Feature | null> => {
        if (newImages.length === 0) {
          return throwError(() => new Error('Failed to upload images'));
        }
        const images = [
          ...existingImages,
          ...newImages.map((image: UploadedImage): Image => image.image),
        ];
        return this.createDefect(uploadData.uploadForm, images, relatedAsset);
      }),
      catchError((error: Error) => {
        console.error(`Failed to upload image(s): ${error}`);
        return of(null);
      }),
    );
  }

  private processEditUpload(
    defect: Feature,
    location: LatLng | null,
    uploadData: PendingUploadGroup,
    relatedAsset: Feature | null,
    existingImages: Image[],
    referenceIdByFileName: Map<string, string>,
  ): OperatorFunction<UploadedImage[], Feature | null> {
    return pipe(
      switchMap((newImages: UploadedImage[]): Observable<UploadedImage[]> => {
        return this.saveAnnotations(newImages, referenceIdByFileName).pipe(
          map((): UploadedImage[] => newImages),
        );
      }),
      switchMap((newImages: UploadedImage[]): Observable<Feature | null> => {
        const images = [
          ...existingImages,
          ...newImages.map((image: UploadedImage): Image => image.image),
        ];
        return this.updateDefect(
          defect,
          uploadData.uploadForm,
          images,
          relatedAsset,
          location,
        ).pipe(map((response: UpdateDefectResponse): Feature | null => response.feature));
      }),
      catchError((error: Error) => {
        console.error(`Failed to edit image group: ${error}`);
        return of(null);
      }),
    );
  }

  private updateDefectFeature(
    defect: Feature,
    imageIds: string[],
    relatedAssetId: string,
    formProperties: Property[],
  ): OperatorFunction<Property[], Feature | null> {
    return pipe(
      mergeMap((relatedAssetProperties: Property[]): Observable<Feature | null> => {
        // Order of the properties matters because we dedup with later item
        // overriding an earlier item with the same key in the list.
        // Duplicates may occur when formProperties have the same properties
        // as the related asset.
        const properties = Object.values(
          [...formProperties, ...relatedAssetProperties].reduce(
            (previous: Record<string, Property>, current: Property) => {
              previous[current.key] = current;
              return previous;
            },
            {},
          ),
        );
        defect.properties = properties;
        return this.featuresService.updateDefect(defect, relatedAssetId, imageIds);
      }),
    );
  }

  private createDefectFeature(
    location: LatLng,
    imageIds: string[],
    tags: Tag[],
    relatedAssetId: string,
    formProperties: Property[],
  ): OperatorFunction<Property[], Feature | null> {
    return pipe(
      mergeMap(
        (relatedAssetProperties: Property[]): Observable<Feature | null> =>
          this.featuresService.addDefect(
            location,
            [...formProperties, ...relatedAssetProperties],
            relatedAssetId,
            tags,
            imageIds,
          ),
      ),
    );
  }
  private imageAssetProperties(asset: Feature | null): OperatorFunction<string, Property[]> {
    return pipe(
      map((address: string): Property[] => {
        const properties: Property[] = [];
        properties.push(
          new Property({
            key: NonFormPropertyName.ADDRESS,
            propertyValue: {case: 'value', value: address},
          }),
        );
        const feederId = asset ? getFeederId(asset) : '';
        if (feederId) {
          properties.push(
            new Property({
              key: PropertyKey.FEEDER_ID,
              propertyValue: {case: 'value', value: feederId},
            }),
          );
        }
        const equipmentId = asset ? getEquipmentId(asset) : '';
        if (equipmentId) {
          properties.push(
            new Property({
              key: PropertyKey.EQUIPMENT_ID,
              propertyValue: {case: 'value', value: equipmentId},
            }),
          );
        }
        return properties;
      }),
    );
  }

  private serialize(pendingUploadGroup: PendingUploadGroup): PendingUploadGroupForDb {
    // Convert from Property protos to stringified version for storage.
    let extraProperties: OfflineProperty[] = [];
    if (pendingUploadGroup.uploadForm?.extraProperties) {
      extraProperties = pendingUploadGroup.uploadForm.extraProperties?.map((property) => {
        return {
          key: property.key,
          value: property.propertyValue.case === 'value' ? property.propertyValue.value : '',
        };
      });
    }
    const uploadForm: UploadForm = {
      tags: pendingUploadGroup.uploadForm?.tags,
      externalId: pendingUploadGroup.uploadForm?.externalId,
      extraProperties: [],
    };
    const annotationsPerFile: AnnotationForDb[][] = [];
    for (const annPF of pendingUploadGroup.annotationsPerFile) {
      const annotationsPerImage: AnnotationForDb[] = [];
      for (const ann of annPF) {
        const annForDB: AnnotationForDb = {
          label: ann.info.label,
          comment: ann.info.comment,
          user: ann.info.user,
          createdAt: ann.info.createdAt,
          updatedAt: ann.info.updatedAt,
          isMachineGenerated: ann.info.isMachineGenerated,
          machineModelVersion: ann.info.machineModelVersion,
          machineConfidenceScore: ann.info.machineConfidenceScore,
          sourceAnnotation: ann.info.sourceAnnotation.toJsonString(),
          shapeType: ann.shapeType,
          shapeData: ann.shapeData,
        };
        annotationsPerImage.push(annForDB);
      }
      annotationsPerFile.push(annotationsPerImage);
    }
    const pendingUploadGroupForDb: PendingUploadGroupForDb = {
      uploadForm,
      files: pendingUploadGroup.files,
      uploadedAt: pendingUploadGroup.uploadedAt,
      locationFromBrowser: '',
      annotationsPerFile,
      extraProperties,
    };
    const location = pendingUploadGroup.locationFromBrowser;
    if (location) {
      pendingUploadGroupForDb.locationFromBrowser = location.toJsonString();
    }
    return pendingUploadGroupForDb;
  }

  private deserialize(
    pendingUploadGroupForDb: PendingUploadGroupForDb | undefined,
    pendingId: number,
  ): PendingUploadGroup | null {
    if (!pendingUploadGroupForDb) {
      return null;
    }
    const annotationsPerFile: Annotation[][] = [];
    for (const annPF of pendingUploadGroupForDb.annotationsPerFile) {
      const annotationsPerImage: Annotation[] = [];
      for (const ann of annPF) {
        const info: AnnotationInfo = {
          label: ann.label,
          comment: ann.comment,
          user: ann.user,
          updatedAt: ann.updatedAt,
          createdAt: ann.createdAt,
          isMachineGenerated: ann.isMachineGenerated,
          machineModelVersion: ann.machineModelVersion,
          machineConfidenceScore: ann.machineConfidenceScore,
          sourceAnnotation: ImageAnnotation.fromJsonString(ann.sourceAnnotation),
        };

        const annForDB: Annotation = {
          info,
          shapeType: ann.shapeType,
          shapeData: ann.shapeData,
        };
        annotationsPerImage.push(annForDB);
      }
      annotationsPerFile.push(annotationsPerImage);
    }

    const pendingUploadGroup: PendingUploadGroup = {
      ...pendingUploadGroupForDb,
      ...{
        locationFromBrowser: null,
        annotationsPerFile,
      },
    };
    const location = pendingUploadGroupForDb.locationFromBrowser;
    if (location) {
      pendingUploadGroup.locationFromBrowser = LatLng.fromJsonString(location);
    }
    const properties: Property[] =
      pendingUploadGroupForDb.extraProperties?.map(
        (property: OfflineProperty): Property =>
          new Property({
            key: property.key,
            propertyValue: {case: 'value', value: property.value},
          }),
      ) || [];
    if (pendingUploadGroup.uploadForm) {
      pendingUploadGroup.uploadForm.extraProperties = properties;
    }
    pendingUploadGroup.pendingUploadID = pendingId;
    return pendingUploadGroup;
  }

  /**
   * Returns a unique group ID.
   */
  private createGroupId(): string {
    this.groupId++;
    return String(this.groupId);
  }

  private uploadSpaceAvailable(
    pendingUploadGroupForDb: PendingUploadGroupForDb,
  ): Observable<boolean> {
    const totalFileSizeBytes = this.totalFileSizeBytes(pendingUploadGroupForDb.files);
    return this.indexedDBService.availableSpaceBytes().pipe(
      mergeMap((availableSpaceBytes: number | null) => {
        if (availableSpaceBytes === null || availableSpaceBytes >= totalFileSizeBytes) {
          return of(true);
        }
        throw new Error('Not enough storage space for saving images');
      }),
    );
  }

  private getLocationChangeFromBrowser$(): Observable<LatLng> {
    return new Observable((subscriber) => {
      if (!navigator?.geolocation?.watchPosition) {
        throw new Error('Navigator geolocation not supported.');
      }
      this.watchPositionId = navigator.geolocation.watchPosition(
        (position: GeolocationPosition) => {
          subscriber.next(
            new LatLng({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }),
          );
        },
        (positionError: GeolocationPositionError) => {
          navigator.geolocation.clearWatch(this.watchPositionId);
          throw new Error(`${GEOLOCATION_ERROR_MESSAGE_BY_CODE.get(positionError.code)}`);
        },
        GEOLOCATION_OPTIONS,
      );
    });
  }

  private uploadState(
    pendingUploadId: number | null,
    uploadState: UploadState,
    uploadData: PendingUploadGroup,
    defect: Feature | null,
  ) {
    this.pendingUploadQueueService.setPendingUploadState(
      pendingUploadId,
      uploadState,
      uploadData,
      defect,
    );
  }

  private uploadNewImages(
    uploadData: PendingUploadGroup,
    location: LatLng | null,
    formName: string,
  ): Observable<UploadedImage[]> {
    if (uploadData.files.length < 1) {
      return of([]);
    }
    const t0 = performance.now();
    const uploadTask = this.gcpUploadEnabled
      ? this.upload(uploadData, location)
      : this.uploadG3(uploadData, location);
    return uploadTask.pipe(
      catchError(() => of([])),
      last(),
      tap((uploaded: UploadedImage[]) => {
        const uploadTimeSec = Math.floor((performance.now() - t0) / 1000);
        if (uploaded.length === 0) {
          this.sendEvent(
            EventActionType.IMAGE_UPLOAD_FAILED,
            uploaded.length,
            uploadTimeSec,
            formName,
          );
          throw new Error('Upload failed.');
        }
        this.sendEvent(EventActionType.IMAGE_UPLOADED, uploaded.length, uploadTimeSec, formName);
      }),
    );
  }

  /** Track events for both number of uploads and upload latency in seconds */
  private sendEvent(
    type: EventActionType,
    fileCount: number,
    uploadTimeSec: number,
    label: string,
  ) {
    this.analyticsService.sendEvent(type, {
      // tslint:disable-next-line:enforce-name-casing
      event_category: EventCategoryType.FORMS,
      // tslint:disable-next-line:enforce-name-casing
      event_label: label,
      value: fileCount,
    });
    // With standard UA and GA4 reports, values are summed, which is
    // not intuitive in line charts as we care more about
    // average upload times rather than the total upload times for a time
    // interval. As a workaround, in UA, we can track upload times with event
    // labels to view within a table; the downside is that we're limited to 50k
    // unique rows on the standard plan.
    this.analyticsService.sendEvent(type, {
      // tslint:disable-next-line:enforce-name-casing
      event_category: EventCategoryType.FORMS,
      // tslint:disable-next-line:enforce-name-casing
      event_label: String(uploadTimeSec),
      value: uploadTimeSec,
    });
  }

  private totalFileSizeBytes(files: File[]) {
    let bytes = 0;
    for (const file of files) {
      bytes += file.size;
    }
    return bytes;
  }
}

/**
 * Returns true if an upload succeeded.
 */
export function uploadSucceeded(uploadResponse: UploadResponse): boolean {
  return Array.from(uploadResponse.values()).every(
    (metadata) => metadata.uploadState === UploadState.SUCCEEDED,
  );
}

/**
 * Returns true if an upload failed with a given state.
 */
export function failedWithState(uploadState: UploadState, uploadResponse: UploadResponse): boolean {
  return Array.from(uploadResponse.values()).some(
    (metadata) => metadata.uploadState === uploadState,
  );
}
